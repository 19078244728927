.root {
  text-align: left;
  max-width: 95%;
  margin: auto;
}

.root h1 {
  font-weight: 800;
  font-size: 36px;
  margin: 0;
}

.root a {
  font-size: 16px;
  margin-bottom: 15px;
}

.root h2 {
  font-weight: 400;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 0;
  text-decoration: underline;
}

.root h3 {
  font-weight: 400;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 0;
}

.root li {
  font-size: 16px;
  margin-bottom: 0;
}
