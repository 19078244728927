.logo {
  transition: transform 0.2s;
  max-height: 42px;
}

.logo:hover {
  transform: scale(1.1);
}

.logoWrapper {
  padding: 0;
}
