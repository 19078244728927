.root {
  max-width: 500px;
  margin: auto;
  margin-bottom: 50px;
}

.row {
  display: flex;
  align-items: center;
}

.col {
  padding: 0;
}
