.darkmodal .modal-content {
  background-color: black;
  border: 1px solid rgb(87, 87, 87);
}

.darkmodal * {
  color: white;
}

.darkmodal .modal-footer,
.darkmodal .modal-body {
  border-top: 1px solid rgb(87, 87, 87);
}

.darkmodal .modal-header {
  border-bottom: 1px solid rgb(87, 87, 87);
}
