/* PianoDarkTheme */

.PianoTheme .ReactPiano__Key--accidental {
  background: black;
  border: 1px solid #888;
}

.PianoTheme .ReactPiano__Key--natural {
  background: white;
  border: 1px solid #888;
  margin-right: 0;
}

.PianoTheme .ReactPiano__Key--active.ReactPiano__Key--accidental {
  background: #e74c3c;
}

.PianoTheme .ReactPiano__Key--active.ReactPiano__Key--natural {
  background: #e74c3c;
}

.ant-switch {
  background-color: rgb(255, 255, 255, 0.3) !important;
  height: 30px !important;
  line-height: 31px !important;
}

.ant-switch-inner {
  font-size: 14px !important;
  margin-left: 32px !important;
}

.ant-switch-checked {
  background-color: black !important;
}

.ant-switch-checked .ant-switch-inner {
  margin-left: 6px !important;
  margin-right: 32px !important;
}

.ant-switch-handle {
  height: 26px !important;
  width: 26px !important;
}

.darkroot {
  background-color: black;
  color: white;
}

.darkroot h1,
.darkroot h2,
.darkroot h3,
.darkroot h4,
.darkroot h5,
.darkroot h6,
.darkroot th,
.darkroot td,
.darkroot .ant-message,
.darkroot .ant-popover-message-title {
  color: white;
}

.darkmodal a {
  color: lightblue;
}

.ant-popover-message {
  font-size: 15px !important;
}

.darkroot input {
  color: black;
}

.darkroot .modal-footer,
.darkroot .ant-popover-inner {
  background-color: black;
  border-style: solid;
  border-color: rgb(83, 83, 83);
  border-width: 1px;
}

.darkroot .ant-popover-placement-top .ant-popover-arrow {
  background-color: black;
  border-style: solid;
  border-right-color: rgb(83, 83, 83) !important;
  border-bottom-color: rgb(83, 83, 83) !important;
  border-width: 1px;
}

.darkroot .ant-popover-placement-bottom .ant-popover-arrow {
  background-color: black;
  border-style: solid;
  border-top-color: rgb(83, 83, 83) !important;
  border-left-color: rgb(83, 83, 83) !important;
  border-width: 1px;
}

.darkroot .ant-message-notice-content {
  background-color: black;
  font-size: 100px;
  border-style: solid;
  border-color: rgb(83, 83, 83);
  border-width: 1px;
}

.lightroot {
  background-color: white;
  color: black;
}

.lightroot h1,
.lightroot h2,
.lightroot h3,
.lightroot h4,
.lightroot h5,
.lightroot h6 {
  color: black;
}

.lightroot input {
  color: black;
}

.ant-message {
  top: 50px !important;
  z-index: 1000000 !important;
}

.ant-message * {
  font-size: 16px;
}

.react-pdf__Page__canvas {
  margin: auto !important;
  max-width: 95vw !important;
  height: auto !important;
}

@media only screen and (max-width: 600px) {
  .hiddenOnMobile {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .showOnWide {
    display: none;
  }
}
