.root {
  margin: auto;
  max-width: 90%;
}

.root h1 {
  font-weight: 800;
  margin: 0;
  margin-bottom: 5px;
}

.root h3 {
  margin-top: 20px;
}

.root img {
  max-height: 300px;
}
