.root {
  max-width: 90%;
  margin: auto;
  text-align: center;
}

.root h1 {
  font-weight: 800;
  margin: 4px;
}

.btn {
  margin: 10px;
  margin-top: 0px;
  margin-right: 5px;
  margin-left: 5px;
}

.pickrow {
  margin: 5% 5%;
  margin-top: 0;
}
.row {
  border: 1px solid #b0b0b0;
  border-radius: 10px;
  overflow-x: scroll;
  margin-bottom: 30px;
  margin-top: 20px;
}

.modal h5 {
  margin-bottom: 20px;
}

.modal li {
  font-size: 20px;
}

.linkbtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  color: #1890ff;
  padding: 0;
}

.linkbtn:hover,
.linkbtn:focus {
  text-decoration: none;
}

.tapbtn {
  width: 200px;
  height: 100px;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 32px;
}
.waveform {
  position: relative;
  overflow: hidden;
}

.waveform canvas {
  position: absolute;
}
