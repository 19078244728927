.uploadBtn {
  margin-bottom: 5px;
  margin-top: 10px;
}

.tr .td {
  margin: auto;
  padding: auto !important;
}

.tr {
  height: 50px;
}

.table {
  margin: auto;
  max-width: 95%;
}

.qrCode {
  border: 20px solid white;
}

.root h6 {
  margin: 5px;
}

.root h3 {
  font-weight: 800;
  margin-bottom: 30px;
}

.connected {
  font-weight: 800;
  font-size: 50px;
  color: green !important;
  margin-bottom: 10px;
}
