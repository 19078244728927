.modeFormTitle {
  font-size: 22px;
  font-weight: 800;
  color: black;
  margin-bottom: 4px !important;
}

.volume {
  margin-bottom: 30px;
}

.startBtn {
  margin-bottom: 30px;
}
