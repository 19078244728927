.root a {
  text-decoration: none;
}

.img {
  margin: 25px auto;
  max-width: 12rem;
}

.cardroot {
  transition: transform 0.2s;
  width: 100%;
  min-width: 18rem;
  color: black;
  margin: 15px 0;
}

.cardroot:hover {
  transform: scale(1.1);
}

.cardroot h1 {
  font-size: 30px;
  font-weight: 800;
}

.darkcardroot {
  background-color: black;
  border: 1px solid #3f3f3f;
}
