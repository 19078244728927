.root {
  max-width: 90%;
  margin: auto;
}

.root h1 {
  margin: 0;
}

.root h2 {
  margin-bottom: 4px;
}

.modeFormBox {
  margin: auto;
  font-size: 18px;
  max-width: 200px;
}

.modeFormTitle {
  font-size: 22px;
  font-weight: 800;
  color: black;
}

.freqInput {
  font-size: 24px;
  margin: 30px 0;
  margin-top: 3px;
  max-width: 100%;
}

.freqInput span {
  max-width: 100%;
}

.freqInput input {
  max-width: 100%;
}

.form {
  padding-bottom: 15px;
}

.volume {
  padding-bottom: 50px;
}

.formRow {
  margin: auto;
  max-width: 430px;
}

.noteFormBox {
  padding-left: 3px;
  padding-right: 3px;
}
