.root {
  width: 90%;
  margin: auto;
}

.connected {
  font-weight: 800;
  font-size: 50px;
  color: green !important;
  margin-bottom: 10px;
}

.btn {
  width: 150px;
  height: 150px;
}

.btn * {
  font-size: 30px;
  font-weight: 800;
}
