.root {
  margin: auto;
  max-width: 90%;
}

.root h2 {
  font-weight: 800;
  margin: 15px 0;
}

.root h4 {
  margin-top: 10px;
  font-weight: 800;
}

.tempoFormTitle {
  font-size: 22px;
  font-weight: 800;
  color: black;
}

.soundFormTitle {
  font-size: 18px;
  color: black;
  margin: 0;
}

.beatTitle {
  font-weight: 800;
  font-size: 22px;
  margin-bottom: 10px;
}

.soundFormBox {
  margin: auto;
  font-size: 16px;
  max-width: 150px;
}

.tempoInput {
  font-size: 24px;
  margin: 30px 0;
  margin-top: 3px;
  max-width: 100%;
}

.tempoInput span {
  max-width: 100%;
}

.tempoInput input {
  max-width: 100%;
}

.tags {
  margin-right: 0;
  height: 100%;
  font-size: 16px;
}

.tags span {
  position: relative;
  margin-top: 12px;
}

.tagInput {
  height: 100%;
  width: 78px;
  font-size: 16px;
  padding-top: 2px;
  margin-bottom: -2px;
}

.tagGroup {
  height: 30px;
  margin-bottom: 30px;
}

.tempoFormBox {
  margin: auto;
  font-size: 18px;
  max-width: 200px;
}

.volume {
  margin-bottom: 40px;
}

.metronomeBody h1 {
  font-size: 144px;
  font-weight: 800;
  margin: 120px;
  text-align: center;
}

.metronomeBodyBall h1 {
  font-size: 144px;
  font-weight: 800;
  margin: 120px;
  margin-top: 50px;
  margin-bottom: 190px;
  text-align: center;
}

.red {
  color: red;
}

.black {
  color: black;
}

.metronomeFooter {
  margin: auto;
  text-align: center;
}

.metronomeFooter p {
  margin: 0;
}

.numericInputWrapper {
  margin: auto;
  max-width: 150px;
}

.switch {
  padding: 15px 0 18px;
}
