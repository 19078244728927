.gauge {
  margin-bottom: -7vw;
  margin-top: -1.5vw;
}

.root h1 {
  font-weight: 800;
  margin: 0;
  font-size: 24px;
}

.root h5 {
  font-size: 24px;
}

.root p {
  margin: 0;
}

.input {
  font-size: 24px;
  margin: 30px 0;
  margin-bottom: 15px;
  max-width: 100%;
}

.input span {
  max-width: 100%;
}

.input input {
  max-width: 100%;
}

.buttonHeight {
  height: 50px;
  padding: 20px;
  border: 0;
}

.progressBar {
  margin: 20px 30%;
}

.modeFormBox {
  margin: auto;
  font-size: 18px;
  max-width: 200px;
}

.modeFormTitle {
  font-size: 22px;
  font-weight: 800;
  color: black;
  margin-bottom: 4px !important;
}

.volume {
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .input {
    font-size: 25px;
    margin: 30px 0;
    margin-bottom: 15px;
    max-width: 70%;
    margin: auto;
  }

  .gauge {
    margin-bottom: -11vw;
    margin-top: -3vw;
  }
}
