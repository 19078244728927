.root {
    max-width: 90%;
    margin: auto;
    text-align: center;
}

.root h1 {
    font-weight: 800;
    margin: 4px;
}

.root h2 {
    margin-top: 50px;
    font-weight: 600;
}

.root h4{
    margin-bottom: 0;
    font-size: 30px;
    margin-top: 0px;
}

.root p {
    font-size: 18px;
    margin-bottom: 100px;
}

.mainIcon {
    max-width: 300px;
    margin-bottom: 20px;
}

.stepIcon {
    max-width: 256px;
    margin-bottom: 0;
}
.row {
    margin: 5% 5%;
    margin-top: 0;
}