.root {
  margin: auto;
  max-width: 90%;
}

.root h2 {
  font-weight: 800;
  margin: 15px 0;
}

.root h4 {
  margin-top: 10px;
  font-weight: 800;
}

.btn {
  margin-bottom: 15px;
  margin-top: 15px;
}

.tapbtn {
  width: 200px;
  height: 100px;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 32px;
}
