.root {
  max-width: 90%;
  margin: auto;
  text-align: center;
}

.root h1 {
  font-weight: 800;
  margin: 4px;
}

.root h2 {
  margin-top: 50px;
  font-weight: 600;
}

.root h4 {
  margin-bottom: 0;
  font-size: 30px;
  margin-top: 0px;
}

.root h5 {
  margin: 10px;
  /* marginBottom: 15px; */
}

.root p {
  font-size: 18px;
  margin-bottom: 0;
}

.btn {
  margin: 10px;
}
