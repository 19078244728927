* {
  font-family: Bahn;
}

@font-face {
  font-family: Bahn;
  src: local("CMU Sans Serif"), url("./assets/fonts/cmunss.eot"),
    url("./assets/fonts/cmunss.ttf") format("truetype");
  /*for CSS3*/
}
