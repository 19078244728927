.root {
  margin: 1%;
  text-align: center;
}

.row {
  margin: 5% 5%;
  margin-top: 0;
}

.root h1 {
  font-weight: 800;
  font-size: 50px;
}

.root h3 {
  margin-top: -20px;
  margin-bottom: -15px;
  font-size: 28px;
}

.crossplatformImg {
  max-width: 75%;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .root h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
}
